import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews, IconReviewStar } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, category, authorDisplayName } = props;

  const [showAllReviews, setShowAllReviews] = useState(false);

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  const ratings = reviews.map(review => review.attributes.rating);
  const averageRating =
    ratings.length > 0
      ? (ratings.reduce((sum, value) => sum + value, 0) / ratings.length).toFixed(2)
      : 0;

  const separator = (
    <React.Fragment>
      &#8203;<span className={css.averageReviewSeparator}>•</span>&#8203;
    </React.Fragment>
  );

  const numRecentReviews = 4;
  const recentReviews = reviews.slice(0, numRecentReviews);

  return (
    <div className={css.sectionReviews}>
      <h2 className={css.reviewsHeading}>
        <FormattedMessage
          id="ListingPage.reviewsHeading"
          values={{ authorDisplayName, category }}
        />
      </h2>

      <p className={css.reviewsAverages}>
        {reviews.length > 0 ? (
          <React.Fragment>
            <span>
              <IconReviewStar rootClassName={css.star} />
              {averageRating}
            </span>
            {separator}
            {reviews.length} reviews
          </React.Fragment>
        ) : (
          <span>No reviews yet</span>
        )}
      </p>

      {fetchReviewsError ? reviewsError : null}
      {showAllReviews ? <Reviews reviews={reviews} /> : <Reviews reviews={recentReviews} />}
      {!showAllReviews && reviews.length > numRecentReviews ? (
        <button
          className={css.showAllReviewsButton}
          type="button"
          onClick={() => setShowAllReviews(true)}
        >
          Show all {reviews.length} trailer reviews
        </button>
      ) : null}
    </div>
  );
};

export default SectionReviews;
