import React from 'react';
import { AvatarExtraLarge, AvatarLarge } from '../../components';

import css from './ListingPage.module.css';

const SectionAvatar = props => {
  const { user } = props;
  return (
    <div className={css.sectionAvatar}>
      <AvatarExtraLarge
        user={user}
        className={css.avatarDesktop}
        initialsClassName={css.initialsDesktop}
        disableProfileLink
      />

      <AvatarLarge user={user} className={css.avatarMobile} disableProfileLink />
    </div>
  );
};

export default SectionAvatar;
