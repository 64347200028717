import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionWeightsMaybe = props => {
  const { atm, dimensions } = props;

  let calculatedTare =
    !!props.payload && !!atm ? Number.parseInt(atm) - Number.parseInt(props.payload) : null;
  let calculatedPayload =
    !!props.tare && !!atm ? Number.parseInt(atm) - Number.parseInt(props.tare) : null;

  const tare = !!props.tare ? props.tare : calculatedTare;
  const payload = !!props.payload ? props.payload : calculatedPayload;

  const internalLength = !!dimensions ? dimensions.length : null;
  const internalWidth = !!dimensions ? dimensions.width : null;

  const payloadTextMaybe = !!payload ? <li>{payload}kg payload (carry capacity)</li> : null;
  const tareTextMaybe = !!tare ? <li>{tare}kg tare (trailer weight)</li> : null;
  const atmTextMaybe = !!atm ? <li>{atm}kg ATM (combined weight when fully loaded)</li> : null;
  const internalLengthTextMaybe = !!internalLength ? (
    <li>{internalLength}m long (internal length)</li>
  ) : null;
  const internalWidthTextMaybe = !!internalWidth ? (
    <li>{internalWidth}m wide (internal width)</li>
  ) : null;

  return !!payload || !!atm ? (
    <div className={css.sectionWeights}>
      <h2 className={css.weightsTitle}>
        <FormattedMessage id="ListingPage.weightsTitle" />
      </h2>
      <ul className={css.weights}>
        {payloadTextMaybe}
        {tareTextMaybe}
        {atmTextMaybe}
        {internalLengthTextMaybe}
        {internalWidthTextMaybe}
      </ul>
    </div>
  ) : null;
};

export default SectionWeightsMaybe;
